import { styled } from "goober";

const MapContainer = styled("div")`
  border: 1px solid var(--shade5);
  background-size: cover;
  width: var(--sp-8_5);
  height: var(--sp-8_5);
  background-image: var(--bg-img);
  border-radius: var(--br-sm);
`;

export default MapContainer;
